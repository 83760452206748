import { FC } from "react";
import { WithContext } from '../../../common/interfaces';
import { PRIVACY_POLICY_ROUTE, TERMS_AND_CONDITIONS_ROUTE } from '../../../constant/routes';

import styles from './styles.module.scss';
import clsx from "clsx";

const LinksMobile: FC<WithContext> = ({ context }) => {

    return (
        <div className={styles.root}>
            <div className="row">
                <div className={`${styles.linksContainer} mb-5`}>
                    {context?.sections?.map((c: any) => (
                        <section key={c.name} className="pb-4">
                            <a
                                key={c.name}
                                className={clsx(!c.url && styles.noLink)}
                                href={c?.url}
                            >
                                <h4>{c?.headline}</h4>
                            </a>
                            <div>
                                {c.sublinksOptional?.map((sublink: any) => (
                                    <a
                                        key={sublink.name}
                                        href={sublink?.url}
                                        target={c.name.includes('Our Partners') || sublink.name.includes('Login') ? '_blank' : ''}
                                        rel={c.name.includes('Our Partners') || sublink.name.includes('Login') ? 'noreferrer' : ''}
                                        className={clsx(sublink.name.includes('Login') && styles.blueLink)}
                                    >
                                        {sublink?.headline}
                                    </a>
                                ))}
                            </div>
                        </section>
                    ))}
                </div>
            </div>

            <section className={styles.terms}>
                <span>{context?.copyright}</span>

                <div>
                    <a className={styles.blueLink} href={TERMS_AND_CONDITIONS_ROUTE}>
                        {context?.termsConditions?.fields?.pageName}
                    </a>
                    <a className={styles.blueLink} href={PRIVACY_POLICY_ROUTE}>
                        {context?.privacyPolicy?.fields?.pageName}
                    </a>
                </div>
            </section>
        </div>
    )
}

export default LinksMobile;
