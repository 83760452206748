import {FC, useContext} from 'react';

import { WithContext } from "../../../common/interfaces";
import { ContentfulContext } from "../../../context";

import styles from './styles.module.scss';

const CurrentOpportunities: FC<WithContext> = ({ context }) => {
    const globalContext = useContext(ContentfulContext);
    const sectionData = context['Paragraph > Current opportunities'];

    return (
        <section className={styles.root}>
            <h3>
                {sectionData?.headline}
            </h3>

            <div className="row d-flex justify-content-center">
                <span className={styles.noJobsMessage}>
                    {sectionData?.paragraph}
                </span>
            </div>

            <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                    <a href="https://www.winning.com.au/careers" target="_blank" rel="noreferrer" className={styles.viewJobsButton}>
                        {(globalContext?.entries as any)['CTA > View all jobs']?.buttonText}
                    </a>
                </div>
            </div>
        </section>
    )
}

export default CurrentOpportunities;
