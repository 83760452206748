import React, { useEffect, useState } from 'react';

import { addBackground } from "../../common/utils";
import { ContactPanel, NetPromoterScore } from "../../common/components";

import styles from './styles.module.scss';
import History from "./History";
import PointsOfDifference from './PointsOfDifference';
import LegendaryCustomers from "./LegendaryCustomers";
import GivingBack from "./GivingBack";
import OurImpact from "./OurImpact";
import AwardBanner from "./AwardBanner";
import HistoryCarousel from "./HistoryCarousel";
import { HomeContext } from './context';
import { ContentfulPicture } from '../../common/interfaces';

const Home = () => {
    const [homeContext, setHomeContext] = useState({} as any);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/home`)
            .then(response => response.json())
            .then(result => {
                setHomeContext(result);
            })
            .catch((err) => console.error(err));

    }, []);

    const backgroundPictures: ContentfulPicture[] = homeContext['Hero image > Home']?.pictures;
    const backgroundImage = backgroundPictures?.find(({ title }) => title.includes('Desktop'))?.file;
    const backgroundImageMobile = backgroundPictures?.find(({ title }) => title.includes('Mobile'))?.file;
    const quote = homeContext['Quote > Bill Parsons > Home'];
    const openQuote = quote?.picturesOptional.find((p: ContentfulPicture) => p.title.includes('open'))?.file
    const closeQuote = quote?.picturesOptional.find((p: ContentfulPicture) => p.title.includes('close'))?.file

    return (
        <HomeContext.Provider value={homeContext}>
            <div className={styles.root}>
                <div className={styles.main} style={addBackground(backgroundImage, backgroundImageMobile)}>
                    <div className={styles.quoteWrapper}>
                        <span className={styles.quote}>
                            {openQuote ? <img src={openQuote} className={styles.quotationMarkOpen} alt="" /> : ''}

                            {quote?.quote}

                            {closeQuote ? <img src={closeQuote} className={styles.quotationMarkClose} alt="" /> : ''}
                        </span>

                        <div className={styles.quoteDescription}>
                            <span className={styles.author}>{quote?.author}</span>
                            <span className={styles.position}>{quote?.role}</span>
                        </div>
                    </div>
                </div>

                <History />

                <HistoryCarousel context={homeContext}/>

                <AwardBanner  context={homeContext}/>
                
                <PointsOfDifference />

                <LegendaryCustomers />

                <NetPromoterScore  context={homeContext}/>
                
                <OurImpact  context={homeContext}/>

                <GivingBack  context={homeContext}/>

                {Object.keys(homeContext).includes('Contact form > Want a winning partner?') && <ContactPanel />}
            </div>
        </HomeContext.Provider>
    )
}

export default Home;
