export const MAIN_ROUTE = "/";
export const HOME_ROUTE = "/home";
export const DELIVERY_ROUTE = "/delivery";
export const INSTALLATION_ROUTE = "/installation";
export const CAREERS_ROUTE = "/careers";
export const PEOPLE_ROUTE = "/people";
export const CONTACT_ROUTE = "/contact";
export const WAREHOUSE_ROUTE = "/warehousing";
export const ABOUT_ROUTE = "/about-winning";
export const TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const SUSTAINABILITY_ROUTE = "/sustainability";
export const ZUORA_FORM_ROUTE = "/subscription*";
export const ZUORA_CALLBACK = "/subscriptionCallback";
