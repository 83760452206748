import {FC} from "react";
import {ZuoraComponent} from "./ZuoraComponent";
import {ZuoraForm} from "./ZuoraForm/ZuoraForm";
import styles from './styles.module.scss';

const Subscribe: FC<{}> = () => {
    return (
        <div className={styles.root}>
            <ZuoraComponent>
                {
                    // @ts-ignore
                    ({ Z }) => (<ZuoraForm Z={Z} />)
                }
            </ZuoraComponent>
        </div>
    )
}

export default Subscribe;