const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function validate(email: string, message: string) {
    let emailErrorMessage = '';
    let messageErrorMessage = '';

    if (email.length === 0) {
        emailErrorMessage = 'This field is required';
    } else if (!EMAIL_REGEX.test(email)) {
        emailErrorMessage = 'Please provide a valid email address';
    }

    if (message.trim().length === 0) {
        messageErrorMessage = 'This field is required';
    }

    return {
        emailErrorMessage,
        messageErrorMessage
    }
}
