import React, { FC } from "react";

import { AutocompleteForm, TrianglePicture } from '../../../common/components';

import styles from './styles.module.scss';
import {ContentfulPicture, WithContext} from "../../../common/interfaces";


const WideDelivery: FC<WithContext> = ({ context }) => {
   const sectionData = context['Paragraph > Australia-wide delivery'];
   const deliveryMap = (sectionData?.pictures as ContentfulPicture[])?.find(({ title }) => title.includes('Desktop'));

   return (
       <div className={styles.root}>
           <TrianglePicture color="blue"/>
           <img src={deliveryMap?.file} className={styles.map} alt=""/>

           <div className={styles.content}>
               <h2>{sectionData?.headline}</h2>
               <p>{sectionData?.subheadline}</p>
               <span>{sectionData?.paragraph}</span>

               <AutocompleteForm/>
           </div>
       </div>
   )
}

export default WideDelivery;
