import { FC } from "react";
import clsx from "clsx";

import {ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss'

interface InstallationProduct {
    name: string;
    overlay: string;
    pictures: ContentfulPicture[];
}

const LINE_OFFSET = 5;

const Products: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Products we install and assemble'];
    const products: InstallationProduct[] | undefined = sectionData?.pictures;

    return (
        <section className={styles.root}>
            <div className="row">
                <div className="col-md-12">
                    <h3>{sectionData?.headline}</h3>
                </div>
            </div>

            <div className="row px-2 px-md-0">
                <div className="col-md-12">
                    <div className={clsx("row", styles.products, styles.rowSafari)}>
                        {products && products.map((product, index) => (
                            <div className={clsx("col-6 col-lg-2 col-md-3", (index % LINE_OFFSET === 0) && 'offset-lg-1')}>
                                <img
                                    src={product.pictures && product.pictures[0].file}
                                    className={clsx(styles.image, "lazyload")}
                                    alt={product.overlay}
                                />

                                <div className={styles.overlay}>
                                    <div className={styles.text}>{product.overlay}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Products;
