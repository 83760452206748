import React, { useEffect, useState } from 'react';
import {ContactPanel, NetPromoterScore} from '../../common/components';

import styles from './styles.module.scss';
import WarehouseHeader from './WarehouseHeader';
import WarehousePartners from './WarehousePartners';
import WarehouseService from './WarehouseService';

const Warehouse = () => {
    const [warehouseContext, setWarehouseContext] = useState<any>({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/warehousing`)
            .then(response => response.json())
            .then(result => {
                setWarehouseContext(result);
            })
            .catch((err) => console.error(err));
    }, []);

  return (
      <div className={styles.root}>
          <WarehouseHeader context={warehouseContext}/>
          <WarehouseService context={warehouseContext}/>
          <WarehousePartners context={warehouseContext}/>
          <NetPromoterScore context={warehouseContext}/>
          {Object.keys(warehouseContext).includes('Contact form > Want a winning partner?') && <ContactPanel/>}
      </div>
  )
}

export default Warehouse;
