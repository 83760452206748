import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { validate } from './utils';

const EmailForm = () => {
  const initialRender = useRef(true);

  const [ctaContext, setCtaContext] = useState<any>({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/contentful/cta`)
        .then(response => response.json())
        .then(result => {
          setCtaContext(result);
        })
        .catch((err) => console.error(err));
  }, []);

  const [emailSent, setEmailSent] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [email, message]);

  function changeName(e: ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  function changeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmailError('');
    setEmail(e.target.value);
  }

  function changeMessage(e: ChangeEvent<HTMLTextAreaElement>) {
    setMessageError('');
    setMessage(e.target.value);
  }

  function send(e: FormEvent) {
    e.preventDefault();

    const { emailErrorMessage, messageErrorMessage } = validate(email, message);

    if (!emailErrorMessage && !messageErrorMessage) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          message,
          name,
        }),
      };
      fetch(`${process.env.REACT_APP_API_URL}/email/send`, requestOptions)
        .then((response) => response.json())
        .then(() => setEmailSent(true))
        .catch(() => {
          setEmailError(emailErrorMessage);
          setMessageError(messageErrorMessage);
        });
    } else {
      setEmailError(emailErrorMessage);
      setMessageError(messageErrorMessage);
    }
  }

  function sendMore() {
    initialRender.current = true;

    setName('');
    setEmail('');
    setMessage('');
    setEmailSent(false);
  }

  return (
    <form autoComplete='on' noValidate className={styles.root} onSubmit={send}>
      <div className={clsx(styles.textField, emailSent && 'd-none')}>
        <input
          name='name'
          autoComplete='name'
          type='text'
          value={name}
          onChange={changeName}
          className={clsx(name && styles.filled)}
        />
        <label>Name</label>
      </div>

      <div
        className={clsx(
          styles.textField,
          styles.required,
          emailError && styles.error,
          emailSent && 'd-none'
        )}
      >
        <input
          name='email'
          autoComplete='email'
          type='email'
          value={email}
          onChange={changeEmail}
          className={clsx(email && styles.filled)}
        />
        <label>Email</label>

        <span>{emailError}</span>
      </div>

      <div
        className={clsx(
          styles.textField,
          styles.required,
          messageError && styles.error,
          emailSent && 'd-none'
        )}
      >
        <textarea
          value={message}
          onChange={changeMessage}
          className={clsx(message && styles.filled)}
        />
        <label>Message</label>

        <span>{messageError}</span>
      </div>

      <button
        type='submit'
        className={clsx(emailSent && 'd-none')}
        disabled={!!emailError || !!messageError}
      >
        {ctaContext['CTA > Send Email']?.buttonText}
      </button>

      <div className={clsx(styles.successMessage, emailSent && styles.visible)}>
        <img src={ctaContext['CTA > Success']?.pictureOptional?.file} alt='' />

        <p>{ctaContext['CTA > Check another one?']?.textOptional}</p>

        <span onClick={sendMore}>{ctaContext['CTA > Check another one?']?.buttonText}</span>
      </div>
    </form>
  );
};

export default EmailForm;
