import {FC, useState} from "react";

interface Props {
    src: string;
    className?: string;
    alt?: string;
}

const LazyImage: FC<Props> = ({ src, className, alt }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <img className={className} src={src} onLoad={() => setLoaded(true)} alt={alt} style={{ display: loaded ? 'block' : 'none'}}/>
    )
}

export default LazyImage;
