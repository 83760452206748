import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

// prevent putting page in bfcache in Safari
// check out https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
window.addEventListener('pagehide', (e) => {
    if (e.persisted) {
        document.body.style.height = '0'
    }
});
window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
        window.location.reload();
    }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
