import React, { FC } from 'react';
import EmailForm from '../EmailForm';

import styles from './styles.module.scss';
import clsx from "clsx";
import useWindowSize from '../../hooks/WindowSize';

interface Props {
    className?: string;
    context?: any;
}

const Wrapper: FC<Props> = ({ className, context }) => {
    const size = useWindowSize();

    const subheadlineWords = context?.subheadline?.slice(1);
    const subheadlineFirstWord = context?.subheadline?.slice(0, 1);

    return (
        <>
            <div className={clsx(styles.root, className)}>
                <div className={styles.title}>
                    <img src={context?.picture?.file} alt="" />
                    <h3>
                        {size.width < 980 && context?.headline}
                        <div className={styles.phoneWrapper}>
                            {subheadlineFirstWord?.map((t: any) => t?.shortText)}
                            <a href={`tel:${subheadlineWords?.map((t: any) => t?.shortText)}`}>
                                <strong>
                                    {subheadlineWords?.map((t: any) => t?.shortText)}
                                </strong>
                            </a>
                        </div>
                    </h3>
                </div>

                <p>{context?.paragraph}</p>
                <span className={styles.FormWrapper}>
                    <EmailForm />
                </span>
            </div>
        </>
    )
}

export default Wrapper;
