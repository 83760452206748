import styles from './styles.module.scss';
import clsx from "clsx";
import EmailForm from '../../common/components/EmailForm';
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";

const Contact = () => {
    const [contactContext, setContactContext] = useState<any>({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/contact`)
            .then(response => response.json())
            .then(result => {
                setContactContext(result);
            })
            .catch((err) => console.error(err));
    }, []);

    const headerData = contactContext['Paragraph > We’d love to hear from you'];
    const mapData = contactContext['Map > 20A Danks Street, Redfern NSW 2016'];

    const mapCenter = {
        lng: mapData?.location.lon || 0,
        lat: mapData?.location.lat || 0,
    }

    const mapDataNZ = contactContext['Map > 72 Taharoto Rd, Takapuna 0622 NZ'];

    const mapCenterNZ = {
        lng: mapDataNZ?.location.lon || 0,
        lat: mapDataNZ?.location.lat || 0,
    }

    return (
        <div>
            <section className={styles.head}>
                <div className={clsx("row", styles.title)}>
                    <div className="col-lg-8 offset-lg-2">
                        <h1>
                            {headerData?.headline}
                        </h1>

                        <p className="subtitle">
                            {headerData?.paragraph}
                        </p>
                    </div>
                </div>
            </section>

            <section className={styles.contact}>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="row">
                            <div className={clsx("col-md-6", styles.formContainer)}>
                                <EmailForm/>
                            </div>

                            <div className={clsx("col-md-6", styles.mapContainer)}>
                                <p className={styles.address}>
                                    <img src={mapData?.picture?.file} className="lazyload" alt=""/>
                                    {mapData?.text}
                                </p>

                                <div className={styles.map}>
                                    <GoogleMap options={{ disableDefaultUI: true, scrollwheel: false, zoomControl: true }} mapContainerClassName={styles.map} zoom={16} center={mapCenter}>
                                        <Marker position={mapCenter}/>
                                    </GoogleMap>
                                </div>
                        
                                <p className={styles.address} >
                                    <img src={mapDataNZ?.picture?.file} className="lazyload" alt=""/>
                                    {mapDataNZ?.text}
                                </p>

                                <div className={styles.map}>
                                    <GoogleMap options={{ disableDefaultUI: true, scrollwheel: false, zoomControl: true }} mapContainerClassName={styles.map} zoom={16} center={mapCenterNZ}>
                                        <Marker position={mapCenterNZ}/>
                                    </GoogleMap>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact;
