import {useContext, useEffect, useState} from "react";

import {ContentfulContext} from "../../../context";

import Card from "./Card";
import styles from './styles.module.scss';
import { HomeContext } from '../context';

const History = () => {
    const context = useContext(ContentfulContext);
    const homeContext = useContext(HomeContext);
    const assets = context?.assets as any;

    return (
        <section className={styles.root}>
            <div className={styles.cardsContainer}>
                {homeContext['Paragraph > Delivery, installation and warehousing']?.sections.map((s:any) => (
                    <Card
                        title={s?.headline}
                        text={s?.subheadline}
                        imageSrc={s?.pictures && s?.pictures[0]?.file}
                        route={s?.link}
                        arrowIcon={assets['Home > Right arrow icon']?.file}
                    />
                ))}
            </div>
        </section>
    )
}

export default History;
