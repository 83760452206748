import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { FC, useEffect, useState } from 'react';
import clsx from "clsx";
import { ContentfulPicture, WithContext } from "../../../common/interfaces";
import styles from './styles.module.scss';
import { MAP_OPTION } from './constant';

interface Location {
	headline: string;
	map: {
		lon: number;
		lat: number;
	}
}


const Locations: FC<WithContext> = ({ context }) => {
	const sectionData = context['Section > Our locations'];

	const locations: Location[] | undefined = sectionData?.locations;
	const locationMarkers: ContentfulPicture[] | undefined = sectionData?.pictures;

	const [selectedLocation, setSelectedLocation] = useState(locations && locations[0]);

	const greyMarker = locationMarkers?.find(({ title }) => title.includes('grey'))?.file;
	const blueMarker = locationMarkers?.find(({ title }) => title.includes('blue'))?.file;


	useEffect(() => {
		setSelectedLocation(locations && locations[0]);
	}, [context, locations])

	return (
		<div className={styles.locations}>
			<div className="row">
				<div className="col-md-12">
					<h3>{sectionData?.headline}</h3>
				</div>
			</div>
			<div className={clsx("row", styles.locationNavigation)}>
				<div className="col-md-10 offset-md-1">
					<ul className={`${styles.sliderMenu} d-none d-md-block`}>
						{locations?.map((location: Location, i: number) => {
							return (
								<li key={i} onClick={() => setSelectedLocation(location)} className={clsx(location.headline === selectedLocation?.headline && styles.underline)}>
									{location.headline}
								</li>
							);
						})}
					</ul>
					<div className={clsx("d-md-none", styles.triangle)}></div>
					<select value={selectedLocation?.headline} onChange={(e) => setSelectedLocation(locations?.find(l => l.headline === e.target.value))} className={clsx("d-md-none", styles.locationSelector)}>
						{locations?.map((location: Location, i: number) => {
							return (
								<option value={location.headline} key={i} className={clsx(location.headline === selectedLocation?.headline && styles.underline)}>
									{location.headline}
								</option>
							);
						})}
					</select>
				</div>
			</div>

			<div className={styles.map}>
				<GoogleMap options={{ disableDefaultUI: true, scrollwheel: false, zoomControl: true, styles: MAP_OPTION.styles }} mapContainerClassName={styles.map} {...MAP_OPTION}>
					{locations?.map((l, i) => {
						return (
							<Marker
								onMouseDown={(e) => {
									e.stop();
									e.domEvent.preventDefault();
									e.domEvent.stopPropagation();
									setSelectedLocation(l);
								}}
								onMouseUp={(e) => {
									e.stop();
									e.domEvent.preventDefault();
									e.domEvent.stopPropagation();
									setSelectedLocation(l);
								}}
								key={i}
								position={{ lat: l.map.lat, lng: l.map.lon }}
								icon={l.headline === selectedLocation?.headline ? blueMarker : greyMarker}
								onClick={() => setSelectedLocation(l)}
							/>
						)
					})}
				</GoogleMap>
			</div>

		</div>
	);
}

export default Locations;
