import React, { FC, useEffect, useState } from 'react';
import clsx from "clsx";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useLocation, useNavigate} from 'react-router-dom';

import DescriptionModal from '../../../common/components/NetPromoterScore/DescriptionModal';
import { ABOUT_ROUTE } from '../../../constant/routes';
import { ContentfulPicture, WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

interface KeyDate {
	name: string;
	headline: string;
	paragraph: string;
}

const AboutWinningTop: FC<WithContext> = ({ context }) => {
	let location = useLocation();
	const navigate = useNavigate();

	const [modalOpened, setModalOpened] = useState(false);

	const headData = context['Paragraph > A long history of unrivalled care'];

	function closeModal() {
		if (!modalOpened) {
			return;
		}
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		// @ts-ignore
		window.scrollTo({ top: parseInt(scrollY || '0') * -1, left: 0, behavior: 'instant' });
		setModalOpened(!modalOpened);
		navigate(ABOUT_ROUTE);
	}

	const backgroundImages: ContentfulPicture[] | undefined = headData?.pictures;
	const backgroundImage = backgroundImages && backgroundImages.find(({ title }) => title.includes('Desktop'))?.file;
	const keyDates: KeyDate[] = context ? Object.entries(context).filter(([key]) => key.includes('Key dates')).map(([, value]) => value) as KeyDate[] : [];
	const middleIndex = Math.ceil(keyDates.length / 2);

	const firstHalf = keyDates.splice(0, middleIndex);
	const secondHalf = keyDates.splice(-middleIndex);

	useEffect(
		() => {
			if (location.hash === '#nps') {
				const scrollY = window.scrollY;
				document.body.style.position = 'fixed';
				document.body.style.top = `-${scrollY}px`;

				setModalOpened(true);
			}
		}, [location]);

	return (
		<div className={styles.root} style={{ backgroundImage: `url(${window.innerWidth > 980 ? backgroundImage : ''})` }} onClick={closeModal}>
			<div className={clsx("row", styles.title)} style={{ backgroundImage: `url(${window.innerWidth < 980 ? backgroundImage : ''})` }}>
				<div className="col-lg-8 offset-lg-2">
					<h1>
						{headData?.headline}
					</h1>
				</div>
				<div className="col-lg-8 offset-lg-2">
					<span className={styles.titleText}>
						{headData && documentToReactComponents(headData.paragraph)}
					</span>
				</div>
			</div>
			<div className={styles.aboutText}>
				<div className={`row ${styles.dateWrapper}`}>
					{firstHalf.map(({ headline, paragraph }) => (
						<div className={styles.dateItem}>
							<div className={`col-md-2 ${styles.year}`}>{headline}</div>
							<div className={`col-md-10 ${styles.historyText}`}>
								<p>{paragraph}</p>
							</div>
						</div>
					))}
				</div>
				<div className={`row ${styles.dateWrapper}`}>
					{secondHalf.map(({ headline, paragraph }) => (
						<div className={styles.dateItem}>
							<div className={`col-md-2 ${styles.year}`}>{headline}</div>
							<div className={`col-md-10 ${styles.historyText}`}>
								<p>{paragraph}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<DescriptionModal opened={modalOpened} onClose={closeModal} />
		</div>
	)
}

export default AboutWinningTop;
