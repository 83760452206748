import clsx from "clsx";
import React, { FC } from "react";

import { AutocompleteForm, TrianglePicture } from '../../../common/components';
import { ContentfulPicture, WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const AssemblyMap: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Australia-wide installations'];

    const installationMap = (sectionData?.pictures as ContentfulPicture[])?.find(({ title }) => title.includes('Desktop'));

    return (
        <section className={styles.root}>
            <TrianglePicture color="blue"/>
            <div className={clsx("row", styles.content)}>
                <div className="col-md-5 offset-md-1">
                    <img
                        src={installationMap?.file}
                        className={clsx("lazyload", styles.map)}
                        alt="Installation map"
                    />
                </div>

                <div className={clsx("col-md-5", styles.form)}>
                    <h3>{sectionData?.headline}</h3>

                    <p className={styles.subtitle}>{sectionData?.subheadline}</p>

                    <p className={styles.caption}>{sectionData?.paragraph}</p>

                    <AutocompleteForm/>
                </div>
            </div>
        </section>
    )
}

export default AssemblyMap;
