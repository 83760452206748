import { FC } from "react";
import clsx from "clsx";

import styles from './styles.module.scss';
import { PRIVACY_POLICY_ROUTE, TERMS_AND_CONDITIONS_ROUTE} from "../../../constant/routes";
import { WithContext } from '../../../common/interfaces';

const Links: FC<WithContext> = ({ context }) => {
    return (
        <div className={styles.root}>
            <section>
                <span>{context?.copyright}</span>

                <div className={styles.terms}>
                    <a className={styles.blueLink} href={TERMS_AND_CONDITIONS_ROUTE}>
                        {context?.termsConditions?.fields?.pageName}
                    </a>
                    <a className={styles.blueLink} href={PRIVACY_POLICY_ROUTE}>
                        {context?.privacyPolicy?.fields?.pageName}
                    </a>
                </div>
            </section>

            {context?.sections?.map((c: any) => (
                <section key={c.name}>
                    <a
                        href={c?.url}
                        className={clsx(!c.url && styles.noLink)}
                    >
                        <h4>{c?.headline}</h4>
                    </a>
                    {c.sublinksOptional?.map((sublink: any) => (
                        <a
                            key={sublink.name}
                            href={sublink?.url}
                            target={c.name.includes('Our Partners') || sublink.name.includes('Login') ? '_blank' : ''}
                            rel={c.name.includes('Our Partners') || sublink.name.includes('Login') ? 'noreferrer' : ''}
                            className={clsx(sublink.name.includes('Login') && styles.blueLink)}
                        >
                            {sublink?.headline}
                        </a>
                    ))}
                </section>
            ))}
        </div>
    )
}

export default Links;
