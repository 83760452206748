import React, {useEffect, useState} from 'react';

import { ContactPanel } from "../../common/components";

import AboutWinningTop from './AboutTop';
import FamilyBrand from './FamilyBrand';
import Locations from './Locations';
import OurHeroes from './OurHeroes';

import styles from './styles.module.scss';

const AboutWinning = () => {
    const [aboutWinningContext, setAboutWinningContext] = useState<any>({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/about-winning`)
            .then(response => response.json())
            .then(result => {
                setAboutWinningContext(result);
            })
            .catch((err) => console.error(err));
    }, []);

  return (
    <div className={styles.root}>
        <AboutWinningTop context={aboutWinningContext}/>
        <Locations context={aboutWinningContext}/>
        <FamilyBrand context={aboutWinningContext}/>
        <OurHeroes context={aboutWinningContext}/>
        {Object.keys(aboutWinningContext).includes('Contact form > Want a winning partner?') && <ContactPanel/>}
    </div>
  )
}

export default AboutWinning;
