import React, { FC } from 'react';
import ReactPlayer from 'react-player/lazy'
import clsx from "clsx";

import {ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const RemovalAndRecycling: FC<WithContext> = ({ context }) => {
	const sectionData = context['Paragraph > Removal and Recycling'];

	const videoPlayerWidth = window.innerWidth > 1900 ? '880px' : window.innerWidth > 980 ? '880px' : '100%';
	const videoPlayerHeight = window.innerWidth > 1900 ? '495px' : window.innerWidth > 980 ? '495px' : `${(window.innerWidth - 40)/1.77}px`;

	return (
		<div className={styles.removalAndRecycling}>
			
			<h3 id={encodeURI(sectionData?.headline)}>{sectionData?.headline}</h3>

			<div style={{width: videoPlayerWidth,  height: videoPlayerHeight }}>
				<div className={styles.animatedBackground}>
					<ReactPlayer
						url={sectionData?.linkOptional}
						controls={true}
						width={videoPlayerWidth}
						height={videoPlayerHeight}
					/>
				</div>
			</div>

			<h4>{sectionData?.subheadline}</h4>

			<p className={styles.subtitle}>
				{sectionData?.paragraph}
			</p>

		</div>
	)
}

export default RemovalAndRecycling;
