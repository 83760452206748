import React, { FC } from 'react';
import ReactPlayer from 'react-player/lazy'
import clsx from "clsx";

import {ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const FamilyBrand: FC<WithContext> = ({ context }) => {
	const sectionData = context['Paragraph > Our family of brands'];

	const logos: { name: string; pictures: ContentfulPicture[] }[] = sectionData ? sectionData.pictures : [];
	const [topLogo] = logos;
	const restLogos = logos.slice(1);

	const videoPlayerWidth = window.innerWidth > 1900 ? '1179px' : window.innerWidth > 980 ? '880px' : '100%';
	const videoPlayerHeight = window.innerWidth > 1900 ? '663px' : window.innerWidth > 980 ? '495px' : `${(window.innerWidth - 40)/1.77}px`;

	return (
		<div className={styles.familyBrand}>
			<ReactPlayer
				url='https://getleda.wistia.com/medias/xbnfyn6alt'
				controls={true}
				width={videoPlayerWidth}
				height={videoPlayerHeight}
			/>


			<h3>{sectionData?.headline}</h3>

			<div className="d-none d-md-block">
				<img src={topLogo?.pictures[0].file}
					className="mb-5 lazyload" alt="" />
				<hr />
				<div className="row">
					<div className={clsx("col-md-10", styles.brands)}>
						{ restLogos.map(({ pictures }) => (
							<img src={pictures[0]?.file} className={`${styles.groupBrands} lazyload`} alt="" />
						))}
					</div>
				</div>
			</div>
			<div className="d-md-none">
				<img src={topLogo?.pictures[0].file}
					style={{ width: "85vw" }}
					className="lazyload" alt="" />
			</div>
			<p className={styles.subtitle}>
				{sectionData?.paragraphOptional}
			</p>
		</div>
	)
}

export default FamilyBrand;
