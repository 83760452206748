import styles from "./styles.module.scss";
import React, {FC} from "react";

interface Props {
    onClick: () => void;
}

const ReadMoreButton: FC<Props> = ({ onClick }) => {
    return (
        <div className={styles.root}>
            <button
                type="button"
                onClick={onClick}
            >
                Read more
            </button>
        </div>
    )
}

export default ReadMoreButton;
