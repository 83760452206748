import { FC, useContext } from "react";

import { ContentfulContext } from "../../../context";

import styles from './styles.module.scss';

interface Props {
    color: 'blue' | 'grey'
}

const TrianglePicture: FC<Props> = ({ color }) => {
    const context = useContext(ContentfulContext);
    const assets = context?.assets as any;

    return (
        <img src={assets[`Triangle (${color})`]?.file} className={styles.triangle} alt=""/>
    )
}

export default TrianglePicture;
