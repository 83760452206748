import React, { useEffect, useState } from 'react';

import Map from './Map';
import Wrapper from "./Wrapper";

const ContactPanel = () => {

    const [contactFormContext, setContactFormContext] = useState<any>({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/contact-form`)
            .then(response => response.json())
            .then(result => {
                setContactFormContext(result);
            })
            .catch((err) => console.error(err));
    }, []);
    return (
        <>
            <Map className="d-none d-md-block"
                location={contactFormContext.map}
                locationNZ={contactFormContext.mapNz}>
                <Wrapper context={contactFormContext}  />
            </Map>

            <Wrapper className="d-md-none" context={contactFormContext} />
        </>
    )
}

export default ContactPanel;
