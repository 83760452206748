import clsx from "clsx";
import React, { useEffect, useState } from 'react';

import { addBackground } from '../../common/utils';
import { ContentfulPicture } from "../../common/interfaces";
import { ContactPanel } from "../../common/components";

import styles from './styles.module.scss';
import CurrentOpportunities from "./CurrentOpportunities";
import WorkingAtWinning from "./WorkingAtWinning";

const Careers = () => {
    const [careersContext, setCareersContext] = useState<any>({});

    const headData = careersContext['Paragraph > Make a difference and be rewarded for it'];

    const backgroundPictures: ContentfulPicture[] = headData ? headData.pictures : [];
    const desktopBackground = backgroundPictures.find(({ title }) => title.includes('Desktop'))?.file || '';
    const mobileBackground = backgroundPictures.find(({ title }) => title.includes('Mobile'))?.file || '';

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/careers`)
            .then(response => response.json())
            .then(result => {
                setCareersContext(result);
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <div>
            <section className={styles.head}>
                <div className={clsx("row", styles.title)} style={addBackground(desktopBackground, mobileBackground)}>
                    <div className="col-lg-8 offset-lg-2">
                        <h1>{headData?.headline}</h1>
                    </div>
                </div>

                <div className={clsx("row", styles.topText)}>
                    <div className="col-lg-8 offset-lg-2 flex-column align-items-center d-flex d-sm-block">
                        <p className={styles.subtitle}>
                            {headData?.subheadline}
                        </p>

                        <p className={styles.titleText}>
                            {headData?.paragraph}
                        </p>
                    </div>
                </div>
            </section>

            <WorkingAtWinning context={careersContext} />

            <CurrentOpportunities context={careersContext} />

            {Object.keys(careersContext).includes('Contact form > Want a winning partner?') && <ContactPanel/>}
        </div>
    )
}

export default Careers;
