import clsx from 'clsx';
import { FC } from "react";

import styles from './styles.module.scss';

interface Props {
  headline: string;
  icon: string;
  imageSrc: string;
  subheadline?: string;
}

const Card: FC<Props> = ({ headline, imageSrc, icon, subheadline }) => {
  const isLaptopSize = window.innerWidth < 1300;

  if (subheadline) {
    return (<div className={clsx(styles.card, styles.cardWithSpan)}>
      <img src={imageSrc} className={styles.cardIcon} alt="" />
      <span>{headline}</span>
      <p style={{ width: '75%' }}>{subheadline}</p>
      <img src={icon} className={styles.shadowedIcon} alt="" />
    </div>
    );
  }
  return (
    <div className={styles.card}>
      <img src={imageSrc} className={styles.cardIcon} alt="" />
      <p style={{ width: isLaptopSize ? '90%' : '100%' }}>
        {headline}
      </p>
      <img src={icon} className={styles.shadowedIcon} alt="" />
    </div>
  );
}

export default Card;
