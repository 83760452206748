import { FC } from "react";

import { WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const DeliveryTimes: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Delivery times'];
    const nextDayAndWeekend = context['Paragraph > Next-day & weekend deliveries']
    const premiumWindows = context['Paragraph > 2-hour premium delivery windows'];

    return (
        <div className={styles.root}>
            <h2>{sectionData?.headline}</h2>

            <p className={styles.subtitle}>{sectionData?.paragraph}</p>

            <div className={styles.content}>
                <section>
                    {nextDayAndWeekend?.pictures && (<img
                        src={nextDayAndWeekend?.pictures[0]?.file}
                        alt={nextDayAndWeekend?.pictures[0]?.title}
                    />)}
                    <div>

                        <span>
                           <strong>
                               {nextDayAndWeekend?.headline}
                            </strong>
                        </span>

                        <p>{nextDayAndWeekend?.paragraph}</p>
                    </div>
                </section>

                <section>
                    {premiumWindows?.pictures && (<img
                        src={premiumWindows?.pictures[0]?.file}
                        alt={premiumWindows?.pictures[0]?.title}
                    />)}

                    <div>
                        <span>
                            <strong>
                                {premiumWindows?.headline}
                            </strong>
                        </span>

                        <p>{premiumWindows?.paragraph}</p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default DeliveryTimes;
