import React, { FC, useState } from 'react';
import clsx from "clsx";

import { ReadMoreButton } from '../../../common/components';
import { ContentfulPicture, WithContext } from "../../../common/interfaces";
import { addBackground } from "../../../common/utils";

import styles from './styles.module.scss';

const WarehouseHeader: FC<WithContext> = ({ context }) => {
  const [infoExpanded, setInfoExpanded] = useState(false);

  const sectionData = context['Paragraph > A tailored warehousing solution, not just storage space'];

  const paragraphs = sectionData?.paragraph?.split('\n\n');

  const backgroundPictures: ContentfulPicture[] = sectionData?.pictures;

  const desktopBackground = backgroundPictures?.find(({ title }) => title.includes('Desktop'))?.file || '';
  const mobileBackground = backgroundPictures?.find(({ title }) => title.includes('Mobile'))?.file || '';

  function toggleExpanded() {
    setInfoExpanded(!infoExpanded);
  }

  return (
    <div className={styles.logisticsTop}>
      <div className={`row ${styles.title}`} style={addBackground(desktopBackground, mobileBackground)}>
        <div className="col-lg-6 offset-lg-3 d-flex justify-content-center">
          <h1>{sectionData?.headline}</h1>
        </div>
        <div className="col-lg-8 offset-lg-2 d-flex justify-content-center">
          <h2>{sectionData?.subheadline}</h2>
        </div>

      </div>

      <div className={clsx('row', styles.logisticsText, infoExpanded && styles.expanded)}>
        <div className={`col-lg-10 ${styles.logisticsParagraph} flex-column align-items-center`}>
          {paragraphs && paragraphs.map((text: string, i: number) => (
            <p key={i}>{text}</p>
          ))}
        </div>

        {!infoExpanded && <ReadMoreButton onClick={toggleExpanded} />}
      </div>
    </div>
  );
}

export default WarehouseHeader;
