import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { ReadMoreButton } from "../../common/components";
import { ContentfulPicture } from "../../common/interfaces";

import styles from "./styles.module.scss";

import RemovalAndRecycling from "./RemovalAndRecycling";
import TacklingWaste from "./TacklingWaste";
import ResponsibleSourcing from "./ResponsibleSourcing";
import CarbonEmissions from "./CarbonEmissions";
import ApproachAndStrategy from "./ApproachAndStrategy";
import GivingBack from "./GivingBack";


const Sustainability = () => {
	const [sustainabilityContext, setSustainabilityContext] = useState<any>({});

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/sustainability`)
			.then((response) => response.json())
			.then((result) => {
				setSustainabilityContext(result);
			})
			.catch((err) => console.error(err));
	}, []);

	const headContent =
		sustainabilityContext["Paragraph > Sustainability"];

	const descriptionParagraphs = (headContent?.paragraph ?? '').split("\n\n");

	return (
		<div className={styles.root}>
			<div
				className={styles.noPicture}
			>
				<h1 className={styles.title}>{headContent?.headline}</h1>
				<h2 className={styles.subtitle}>{headContent?.subheadline}</h2>

				<div className="col-md-10 offset-md-1">
					<ul className={styles.sliderMenu}>
						{descriptionParagraphs.map((paragraph:any) => (  
						<li><a href={"#".concat(encodeURI(paragraph))}>{paragraph}</a></li>  
						))}  
					</ul>
				</div>
			</div>

			<RemovalAndRecycling context={sustainabilityContext} />
			<TacklingWaste context={sustainabilityContext} />
			<ResponsibleSourcing context={sustainabilityContext} />
			<CarbonEmissions context={sustainabilityContext} />
			<GivingBack context={sustainabilityContext} />
			<ApproachAndStrategy context={sustainabilityContext} />
			
		</div>
	);
};

export default Sustainability;
