import { FC } from "react";

import { ContentfulPicture, LogoBound, WithContext } from "../../../common/interfaces";
import { renderBrands } from '../../../common/utils';

import styles from './styles.module.scss';

const isLargeLaptop = window.innerWidth < 1720;
const isLaptop = window.innerWidth < 1480;
const isLaptopWithScaling = window.innerWidth < 1281;
const isMobile = window.innerWidth < 768;

const BOUNDS: LogoBound[] = [
    { height: isLaptopWithScaling ? 40 : isLaptop ? 44 : 60 },
    { height: isLaptopWithScaling ? 50 : isLaptop ? 60 : 60 },
    { height: isLaptopWithScaling ? 40 : isLaptop ? 50 : 60 },
    { height: isLaptopWithScaling ? 30 : isLaptop ? 39 : 50 },
    { height:isLaptopWithScaling ? 50 : isLaptop ? 65 : 80 },
    { height: isLaptopWithScaling ? 55 : isLaptop ? 75 : 70 },
    { height: isLaptopWithScaling ? 30 : 40 },
    { height: isLaptopWithScaling ? 50 : 60 },
    { height: isMobile ? 51 : isLaptop ? 63 : isLargeLaptop ? 80 : 70 },
]

const FIRST_LINE_OFFSET = 4;

const WarehousePartners: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Trusted warehousing partner for'];
    const logos: { pictures: ContentfulPicture[] }[] = sectionData?.pictures;

    return (
        <section className={styles.root}>
            <div className="row">
                <div className="col-md-12">
                    <h3>{sectionData?.headline}</h3>
                </div>
            </div>
            <div className="row">
                {logos && renderBrands(logos.map(({ pictures}) => pictures[0]), FIRST_LINE_OFFSET, BOUNDS, styles)}
            </div>
        </section>
    )
}

export default WarehousePartners;
