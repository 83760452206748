import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { ReadMoreButton, NetPromoterScore } from "../../common/components";
import { ContentfulPicture } from "../../common/interfaces";
import { ContactPanel } from "../../common/components";

import styles from './styles.module.scss';
import Brands from "./Brands";
import WideDelivery from "./WideDelivery";
import DeliveryProcess from "./DeliveryProcess";
import DeliveryTimes from "./DeliveryTimes";

const Delivery = () => {
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);
    const [deliveryContext, setDeliveryContext] = useState<any>({
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/delivery`)
            .then(response => response.json())
            .then(result => {
                setDeliveryContext(result);
            })
            .catch((err) => console.error(err));
    }, []);

    const headContent = deliveryContext['Paragraph > Delivery people who really care'];

    const backgroundPictures: ContentfulPicture[] = deliveryContext['Paragraph > Delivery people who really care']?.pictures;

    const desktopBackground = backgroundPictures?.find(({ title }) => title.includes('Desktop'))?.file;
    const mobileBackground = backgroundPictures?.find(({ title }) => title.includes('Mobile'))?.file;

    const descriptionParagraphs = headContent?.paragraph?.split('\n\n');

    function toggleDescription() {
        setDescriptionExpanded(!descriptionExpanded);
    }

    return (
        <div className={styles.root}>
            <div
                className={styles.mainPicture}
                style={{ backgroundImage: `url(${window.innerWidth > 768 ? desktopBackground : mobileBackground})`}}
            >
                <h1 className={styles.title}>
                    {headContent?.headline}
                </h1>
                <h2 className={styles.subtitle}>{headContent?.subheadline}</h2>
            </div>

            <div className={clsx(styles.description, 'row', descriptionExpanded && styles.expanded)}>
                <div className={clsx('offset-md-1 col-md-5 d-flex flex-column align-items-center', styles.paragraph1)}>
                    <p>{descriptionParagraphs && descriptionParagraphs[0]}</p>
                    <p>{descriptionParagraphs && descriptionParagraphs[1]}</p>
                </div>

                <div className={clsx('col-md-5 d-flex flex-column align-items-center', styles.paragraph2)}>
                    <p>{descriptionParagraphs && descriptionParagraphs[2]}</p>
                    <p>{descriptionParagraphs && descriptionParagraphs[3]}</p>
                </div>

                {!descriptionExpanded && <ReadMoreButton onClick={toggleDescription}/>}
            </div>

            <Brands context={deliveryContext}/>

            <WideDelivery context={deliveryContext}/>

            <DeliveryProcess context={deliveryContext}/>

            <DeliveryTimes context={deliveryContext}/>

            <NetPromoterScore context={deliveryContext}/>

            {Object.keys(deliveryContext).includes('Contact form > Want a winning partner?') && <ContactPanel/>}
        </div>
    )
}

export default Delivery;
