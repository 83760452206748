import React, {useContext} from "react";

import styles from './styles.module.scss';
import { HomeContext } from '../context';
import Card from './Card';
import { ContentfulPicture } from '../../../common/interfaces';

const PointsOfDifference = () => {
    const homeContext = useContext(HomeContext);


    const header = homeContext['Headline > Our Points of Difference']?.headline;

    const quote = homeContext['Quote > John, Belgrave South, Victoria'];

    const quoteLines: string[] = quote?.quote?.split('\n');

    const cards = homeContext['Set of images > Our points of difference']?.sections;

    return (
        <section className={styles.root}>
            <h2>{header}</h2>
            <div className={styles.cards}>
                <div className={styles.cardRow}>
                    {cards?.map((c: any) => (
                        <Card
                            key={c?.headline}
                            headline={c?.headline}
                            icon={c?.pictures && c?.pictures[0]?.pictures.find((p:ContentfulPicture) => p.title.includes('background'))?.file}
                            imageSrc={c?.pictures && c?.pictures[0]?.pictures.find((p: ContentfulPicture) => p.title.includes('main'))?.file}
                            subheadline={c?.subheadlineOptional}
                        />
                    ))}
                </div>

            </div>

            <div className={styles.quote}>
                <p>
                    {quoteLines?.map((text) => <span key={text}>{text} <br/></span>)}
                </p>
                <span>{`${quote?.author || ''}${quote?.author && quote?.role ? ', ' : ''}${quote?.role || ''}`}</span>

                {quote?.picturesOptional[0]?.file ? (<img src={quote?.picturesOptional[0]?.file} alt="" className={styles.leftMark}/>) : ''}
                {quote?.picturesOptional[1]?.file ? (<img src={quote?.picturesOptional[1]?.file} alt="" className={styles.rightMark}/>) : '' }
            </div>
        </section>
    )
}

export default PointsOfDifference;
