import { FC, useEffect, useState } from "react";
import clsx from "clsx";

import styles from './styles.module.scss';

interface Props {
    opened: boolean;
    onClose: () => void;
}

const DescriptionModal: FC<Props> = ({ opened, onClose }) => {

    const [modalContext, setModalContext] = useState({} as any);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/net-promoter-scrores-modal`)
            .then(response => response.json())
            .then(result => {
                setModalContext(result);
            })
            .catch((err) => console.error(err));

    }, []);

    const paragraphs = modalContext['Paragraph > The happiest customers in the industry']?.paragraph?.split('\n\n');

    return (
        <div className={clsx(styles.root, opened && styles.opened)}>
            <div className={styles.content} onClick={(e) => e.stopPropagation()}>
                <span className={styles.closeButton} onClick={onClose}>×</span>

                <h2>{modalContext['Paragraph > The happiest customers in the industry']?.headline}</h2>

                <p>{paragraphs && paragraphs[0]}</p>

                <p>{paragraphs && paragraphs[1]}</p>

                <div className={styles.score}>
                    <img
                        src={modalContext['Paragraph > Rate us 9 or 10 out of 10!']?.pictures && modalContext['Paragraph > Rate us 9 or 10 out of 10!']?.pictures[0]?.file}
                        alt={modalContext['Paragraph > Rate us 9 or 10 out of 10!']?.pictures && modalContext['Paragraph > Rate us 9 or 10 out of 10!']?.pictures[0]?.title} />
                    <div className={styles.scoreInfo}>
                        <h3>{modalContext['Paragraph > Rate us 9 or 10 out of 10!']?.headline}</h3>

                        <span>{modalContext['Paragraph > Rate us 9 or 10 out of 10!']?.paragraph}</span>
                    </div>
                </div>

                <span>
                    <a
                        href={modalContext['Link > Learn how the Net Promoter Score (NPS) is measured']?.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {modalContext['Link > Learn how the Net Promoter Score (NPS) is measured']?.headline}
                    </a>
                </span>
            </div>
        </div>
    )
}

export default DescriptionModal;
