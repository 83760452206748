import { FC } from "react";

import { WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const CarbonEmissions: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Carbon Emissions'];
    const paragraphs = (sectionData?.paragraph ?? '').split("\n\n");

    return (
        <div className={styles.root}>
            <h3 id={encodeURI(sectionData?.headline)}>{sectionData?.headline}</h3>

            <div className={styles.content}>
                {sectionData && sectionData['pictures'] && <img src={sectionData['pictures'][0]?.file} alt="Carbon Emissions Banner"/>}
                <h4>{sectionData?.subheadline}</h4>
                <p>
                    {paragraphs.map((paragraph:any) => (  
                        <>
						{paragraph}
                        <br/>
                        <br/>
                        </>
					))}  
                </p>
            </div>
        </div>
    )
}

export default CarbonEmissions;
