import { useContext, useEffect } from "react";
import clsx from "clsx";

import { ContentfulContext } from "../../context";
import { renderRichText } from "../../common/utils";

import styles from "./styles.module.scss";

const PrivacyPolicy = () => {
	const context = useContext(ContentfulContext);

	useEffect(() => {
		const richText = document.getElementById("rich-text");
		const links = richText ? richText.querySelectorAll("a") : [];

		links.forEach((link) => {
			link.target = "_blank";
			link.rel = "noreferrer";
		});
	}, [context]);

	const entries = context?.entries as any;

	return (
		<div className={styles.root}>
			<section>
				<div className={clsx("row", styles.title)}>
					<div className="col-lg-8 offset-lg-2">
						<h1>{entries["Paragraph > Privacy policy"]?.headline}</h1>
					</div>
				</div>
			</section>

			<section className={styles.content}>
				<div className="row">
					<div className="col-md-8 offset-md-2 g-4" id="rich-text">
						{renderRichText(entries["Paragraph > Privacy policy"]?.paragraph)}
					</div>
				</div>
			</section>
		</div>
	);
};

export default PrivacyPolicy;
