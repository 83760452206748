import {FC} from "react";
import styles from './styles.module.scss';

interface ZuoraCallbackProps {
}
const ZuoraCallback: FC<ZuoraCallbackProps> = ({}) => {
    return (
        <div className={styles.root}>
            <main className={styles.main}>
                <h1> Thank You. Your payment details for your extended storage have been successfully registered.</h1>
            </main>
        </div>
    )
}

export default ZuoraCallback;
