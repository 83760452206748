import { FC } from "react";

import { WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const TacklingWaste: FC<WithContext> = ({ context }) => {
    const ABSCsectionData = context['Paragraph > Australian Bedding Stewardship Council'];
    const ABSCparagraphsAddHTMLlinks = ABSCsectionData?.paragraph.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a target="_blank" href="$2">$1</a>') ?? ''
    const ABSCparagraphs = (ABSCparagraphsAddHTMLlinks ?? '').split("\n\n");

    const AllHeartNZsectionData = context['Paragraph > All Heart NZ'];
    const AllHeartNZparagraphsAddHTMLlinks = AllHeartNZsectionData?.paragraph.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a target="_blank" href="$2">$1</a>') ?? ''
    const AllHeartNZparagraphs = (AllHeartNZparagraphsAddHTMLlinks ?? '').split("\n\n");
    
    return (
        <div className={styles.root}>
            <h4>{ABSCsectionData?.headline}</h4>

            <div className={styles.content}>
                {ABSCsectionData && ABSCsectionData['pictures'] && <img src={ABSCsectionData['pictures'][0]?.file} alt="ABSC logo"/>}
                <p>
                    {ABSCparagraphs.map((paragraph:any) => (  
                        <>
						<span dangerouslySetInnerHTML={{__html: paragraph}} />
                        <br/>
                        <br/>
                        </>
					))}  
                </p>
            </div>

            <div className={styles.content}>
                {AllHeartNZsectionData && AllHeartNZsectionData['pictures'] && <img src={AllHeartNZsectionData['pictures'][0]?.file} alt="All Heart NZ logo"/>}
                <p>
                    {AllHeartNZparagraphs.map((paragraph:any) => (  
                        <>
                        <span dangerouslySetInnerHTML={{__html: paragraph}} />
                        <br/>
                        <br/>
                        </>
                    ))}  
                </p>
            </div>
        </div>
    )
}

export default TacklingWaste;
