import clsx from "clsx";
import { FC } from 'react';

import { ContentfulPicture, WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const WorkingAtWinning: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Working at Winning Services'];
    const quoteData = context['Quote > Bill Parsons > Careers'];

    const allWorkersPictures: ContentfulPicture[] = sectionData ? sectionData.pictures[0].pictures : [];
    const filteredWorkersPictures = allWorkersPictures.filter(({ title }) => title.includes('Desktop'));

    const openQuote = quoteData?.picturesOptional.find((p: ContentfulPicture) => p.title.includes('open'))?.file;
    const closeQuote = quoteData?.picturesOptional.find((p: ContentfulPicture) => p.title.includes('close'))?.file;

    function renderWorkersImages(images: ContentfulPicture[]) {
        const [img1, img2, img3, img4, img5, img6] = images;
        return (
            <>
                <div className={styles.leftCol}>
                    <div className="row">
                        <div className="col-sm-12 col-md-10 col-lg-12 col-10 offset-1 offset-lg-0">
                            <img className="lazyload" src={img1?.file} alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 offset-1 offset-lg-0">
                            <img className="lazyload" src={img2?.file} alt="" />
                        </div>
                        <div className="col-5 offset-lg-1">
                            <img className={clsx(styles.last, "lazyload")} src={img3?.file} alt="" />
                        </div>
                    </div>
                </div>

                <div className={clsx(styles.rightCol, "d-none d-md-block offset-md-1 offset-lg-0")}>
                    <div className={clsx('float-start me-md-1 me-lg-0', styles.worker4)}>
                        <img className="lazyload" src={img4?.file} alt="" />
                    </div>
                    <div className="float-start">
                        <img className={clsx(styles.last, "lazyload")} src={img5?.file} alt="" />
                        <br />
                        <img className={clsx(styles.last, "lazyload")} src={img6?.file} alt="" />
                    </div>
                </div>

                <div className={clsx(styles.rightCol, "d-md-none")}>
                    <div className="row">
                        <div className="col-5 offset-1">
                            <img src={img4?.file} className={clsx(styles.worker4, "lazyload")} alt="" />
                        </div>
                        <div className="col-5">
                            <img className={clsx(styles.last, "lazyload")} src={img5?.file} alt="" />
                            <br />
                            <img className={clsx(styles.last, "lazyload")} src={img6?.file} alt="" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <section className={styles.root}>
            <div className="row">
                <div className={`col-md-10 offset-md-1 ${styles.head}`}>
                    <h3>
                        {sectionData?.headline}
                    </h3>

                    <p className={styles.subtitle}>
                        {sectionData?.subheadlineOptional}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className={styles.imageContainer}>
                    {renderWorkersImages(filteredWorkersPictures)}
                </div>
            </div>
            <div className="row px-3">
                <div className={styles.quoteContainer}>
                    <div className={styles.workingText}>
                        <img
                            src={openQuote}
                            className={styles.quotationBegin}
                            alt=""
                        />
                        <span className={clsx("text-center", styles.subtitle)}>
                            {quoteData?.quote}
                        </span>

                        <div className={styles.workingSignature}>
                            <span>{quoteData?.author}</span>
                            <p> {quoteData?.role}</p>
                        </div>

                        <img
                            src={closeQuote}
                            className={styles.quotationEnd}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WorkingAtWinning;
