import React, { FC } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

import styles from './styles.module.scss';

const MAP_CENTER = {
    lat: -35.448085,
    lng: 151.973808
}

interface Props {
    className?: string;
    location?: { lon: number, lat: number }
    locationNZ?: { lon: number, lat: number }
}

const Map: FC<Props> = ({ children, className, location, locationNZ }) => {
    const mapAU = {
        lng: location?.lon || 0,
        lat: location?.lat || 0,
    }

    const mapNZ = {
        lng: locationNZ?.lon || 0,
        lat: locationNZ?.lat || 0,
    }

    return (
        <div className={className}>
            <GoogleMap
                options={{ disableDefaultUI: true, scrollwheel: false, zoomControl: true }}
                mapContainerClassName={styles.map}
                zoom={5}
                center={MAP_CENTER}
            >
                {children}
                <Marker position={mapAU} />
                <Marker position={mapNZ} />
            </GoogleMap>
        </div>
    )
}

export default Map;
