import React, { FC, useState, useEffect } from "react";
import "./App.css";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import {
	Home,
	Header,
	Footer,
	Delivery,
	Installation,
	Careers,
	People,
	Contact,
	Warehouse,
	TermsAndConditions,
	PrivacyPolicy,
	ScrollToTop,
	Subscribe,
	ZuoraCallback,
	Sustainability,
} from "./components";
import {
	DELIVERY_ROUTE,
	MAIN_ROUTE,
	INSTALLATION_ROUTE,
	CAREERS_ROUTE,
	PEOPLE_ROUTE,
	CONTACT_ROUTE,
	WAREHOUSE_ROUTE,
	ABOUT_ROUTE,
	TERMS_AND_CONDITIONS_ROUTE,
	PRIVACY_POLICY_ROUTE,
	SUSTAINABILITY_ROUTE,
	HOME_ROUTE,
	ZUORA_FORM_ROUTE,
	ZUORA_CALLBACK,
} from "./constant/routes";
import { ContentfulContext } from "./context";
import AboutWinning from "./components/About";
import { MAPS_API_KEY } from "./constant/map";

const App: FC = () => {
	const [context, setContext] = useState({
		entries: {},
		assets: {},
	});

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/contentful`)
			.then((response) => response.json())
			.then((result) => {
				setContext(result);
			})
			.catch((err) => console.error(err));
	}, []);

	return (
		<div style={{ position: "relative" }}>
			<ContentfulContext.Provider value={context}>
				<LoadScript googleMapsApiKey={MAPS_API_KEY}>
					<BrowserRouter>
						<Header />

						<Routes>
							<Route path={MAIN_ROUTE} element={<Home />} />
							<Route path={HOME_ROUTE} element={<Home />} />
							<Route path={DELIVERY_ROUTE} element={<Delivery />} />
							<Route path={INSTALLATION_ROUTE} element={<Installation />} />
							<Route path={CAREERS_ROUTE} element={<Careers />} />
							<Route path={PEOPLE_ROUTE} element={<People />} />
							<Route path={CONTACT_ROUTE} element={<Contact />} />
							<Route path={WAREHOUSE_ROUTE} element={<Warehouse />} />
							<Route path={ABOUT_ROUTE} element={<AboutWinning />} />
							<Route
								path={TERMS_AND_CONDITIONS_ROUTE}
								element={<TermsAndConditions />}
							/>
							<Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
							<Route path={SUSTAINABILITY_ROUTE} element={<Sustainability />} />
							<Route path={ZUORA_FORM_ROUTE} element={<Subscribe />} />
							<Route path={ZUORA_CALLBACK} element={<ZuoraCallback />} />
						</Routes>

						<Footer />

						<ScrollToTop />
					</BrowserRouter>
				</LoadScript>
			</ContentfulContext.Provider>
		</div>
	);
};

export default App;
