import { FC } from "react";

import {ContentfulLogo, ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const GivingBack: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Giving Back'];

    const paragraphs = (sectionData?.paragraphOptional ?? '').split("\n\n");
    
    const logos: ContentfulLogo[] = sectionData ? sectionData.pictures : [];
    const logoCollection = logos
    .filter(logo => logo.pictures)
    .map( logo => {
        return{
            ...logo,
            pictures:(logo.pictures as ContentfulPicture[])[0] ?? ''
        };
    });
    
    return (
        <div className={styles.root}>
            <h3 id={encodeURI(sectionData?.headline)}>{sectionData?.headline}</h3>

            <div className={styles.content}>
                <p>
                    {paragraphs.map((paragraph:any) => (  
                        <>
						{paragraph}
                        <br/>
                        <br/>
                        </>
					))}  
                </p>
                <h4>{sectionData?.subheadlineOptional}</h4>
                <div className={styles.row}>
				{logoCollection.map(({ pictures, headline, link }) => (
                    <div className={styles.column}>
                        <div className={styles.logo_container}>
                            <img src={pictures?.file} className={styles.logo} alt="" />
                        </div>
                        <p className={styles.subtitle}>{headline}</p>                       
                    </div>
				))}
				</div>
            </div>
        </div>
    )
}

export default GivingBack;
