import { BLOCKS } from "@contentful/rich-text-types";
import {CSSProperties, ReactNode} from "react";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import {ContentfulPicture, LogoBound} from "./interfaces";
import clsx from "clsx";

export function addBackground(desktopImage?: string, mobileImage?: string) {
    return {
        backgroundImage: `url(${window.innerWidth > 980 ? desktopImage : mobileImage || desktopImage})`
    }
}

export function renderRichText(document: any) {
    const options: Options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => <p>{children}</p>
        },
        renderText: (text: string) => {
            if (text === '') {
                return <br/>
            }

            return text.replace(/\r/g, '').split("\n").flatMap((text, i) => [i > 0 && <br/>, text])
        }
    }

    return documentToReactComponents(document, options);
}

function renderBrandLogo(logo: ContentfulPicture, bounds: LogoBound) {
    const { maxHeight, height, maxWidth } = bounds;

    const style: CSSProperties = {};
    if (height) {
        style.height = `${height}px`;
    }
    if (maxHeight) {
        style.maxHeight = `${maxHeight}px`;
    }
    if (maxWidth) {
        style.maxWidth = `${maxWidth}px`;
    }

    return (
        <img src={logo?.file} alt={logo?.title} style={style} key={logo?.title}/>
    )
}

export function renderBrands(
    partners: ContentfulPicture[],
    firstLineOffset: number,
    bounds: LogoBound[],
    styles: Record<string, string>,
    swapLastItems?: boolean
) {
    const partnersLine1 = partners.slice(0, firstLineOffset);
    const partnersLine2 = partners.slice(firstLineOffset);

    const partnersForMobile = [...partners];

    // Swap last and pre-last logos on mobile
    if (swapLastItems && window.innerWidth < 768) {
        const lastLogo = partnersForMobile[partnersForMobile.length - 1];

        partnersForMobile[partnersForMobile.length - 1] = partnersForMobile[partnersForMobile.length - 2];
        partnersForMobile[partnersForMobile.length - 2] = lastLogo;
    }

    const defaultLogoBound: LogoBound = {
        height: 45
    }

    return (
        <>
            <div className="d-none d-md-block">
                <div className={styles.brands}>
                    {partnersLine1.map((partner, index) => renderBrandLogo(partner, bounds[index] || defaultLogoBound))}
                </div>

                <div className={clsx(styles.brands, styles.wideBrands)}>
                    {partnersLine2.map((partner, index) => renderBrandLogo(partner, bounds[firstLineOffset + index] || defaultLogoBound))}
                </div>
            </div>

            <div className={clsx("d-md-none", styles.brandsMobile)}>
                {partnersForMobile.map((partner, index) => renderBrandLogo(partner, bounds[index] || defaultLogoBound))}
            </div>
        </>
    )
}
