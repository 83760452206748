import { useState, useEffect, FormEvent, ChangeEvent } from 'react';

import styles from './styles.module.scss';

const AutocompleteForm = () => {
  const [ctaContext, setCtaContext] = useState<any>({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/contentful/cta`)
        .then(response => response.json())
        .then(result => {
          setCtaContext(result);
        })
        .catch((err) => console.error(err));
  }, []);

  const [data, setData] = useState([]);
  const [location, setLocation] = useState('');
  const [locationStatus, setLocationStatus] = useState(false);
  const [locationSent, setLocationSent] = useState(false);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/locality-manager/localities`)
      .then(response => response.json())
      .then((data) => setData(data.map(insertSpaces)))
  }, [])

  function insertSpaces(location: string) {
    return location.split(',').join(', ');
  }

  function removeSpaces(location: string) {
    return location.split(', ').join(',');
  }

  function checkLocation(e: FormEvent) {
    e.preventDefault();
    let address = location;
    if (filteredSuggestions && filteredSuggestions[0]) {
      address = filteredSuggestions[0];
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ address: removeSpaces(address) }),
    };

    fetch(`${process.env.REACT_APP_API_URL}/locality-manager/availability`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        setLocationSent(true);
        setLocationStatus(data);
        setLocation(address);
      })
      .catch(() => {
        setLocationStatus(false);
        setLocationSent(true);
      });
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;

    const unLinked = data.filter((l: string) => l.toLowerCase().indexOf(userInput.toLowerCase()) > -1).slice(0, 5);

    setLocation(e.target.value);
    setFilteredSuggestions(unLinked);
    setShowSuggestions(true);
  };

  const onClick = (e: any) => {
    setFilteredSuggestions([]);
    setLocation(e.target.innerText);
    setShowSuggestions(false);
  };

  function checkMore() {
    setLocation('');
    setLocationSent(false);
  }

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul>
        {filteredSuggestions.map((suggestion) => {
          return (
            <li key={suggestion} onClick={onClick}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : null;
  };

  const messagePicture = locationStatus ? ctaContext['CTA > Success']?.pictureOptional?.file : ctaContext['CTA > Failed']?.pictureOptional?.file;

  const LocationForm = (
    <form className={styles.root} onSubmit={checkLocation}>
      <div className={styles.inputWrapper}>
        <img
          src={ctaContext['CTA > Confirm']?.pictureOptional?.file}
          alt=""
          draggable={false}
        />
        <input
          onChange={onChange}
          value={location}
          type="text"
        />
        {showSuggestions && location && <SuggestionsListComponent />}
      </div>

      <button type="submit" disabled={!location}>
        {ctaContext['CTA > Confirm']?.buttonText}
      </button> 
    </form>
  );

  const ResponseView = (
    <div>
      <div className={`${styles.notificationBox}`}>
        {messagePicture && (<img
            src={messagePicture}
            className="lazyload"
            alt="Available"
        />)}
        {locationStatus ? ctaContext['CTA > Success']?.textOptional : ctaContext['CTA > Failed']?.textOptional} {location}
      </div>
      <div>
        <span className={styles.returnMessage} onClick={checkMore}>
          {locationStatus ? ctaContext['CTA > Success']?.buttonText : ctaContext['CTA > Failed']?.buttonText}
        </span>
      </div>
    </div>
  )

  return locationSent ? ResponseView : LocationForm;
}

export default AutocompleteForm;
