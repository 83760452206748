import React, {FC, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import './ZuoraForm.scss';
import axios from "axios";

interface ZuoraSetupParams {
    id: string;
    tenantId: number;
    token: string;
    key: string;
    signature: string;
    submitEnabled: "true" | "false";
    locale: string;
    param_supportedTypes: string;
    url: string;
    style: "inline" | "overlay";
    field_accountId?: string;
    brand?: string;
    paymentGateway?: string;
}

interface ZuoraFormProps {
    Z: any // Zuora Lib prop
}
export const ZuoraForm: FC<ZuoraFormProps> = ({ Z }) => {

    const [brand, setBrand] = useState<string>('');
    const [accountid, setAccountid] = useState<string>('');
    const [params, setParams] = useState<ZuoraSetupParams|null>(null);
    const [ currentToken , setCurrentToken ] = useState<string>('')

    const setUpZuora = useCallback(  async (brand:string, accountid: string) => {
        return new Promise( async(resolve, reject)=>{
            try {
                const res_token = await axios.post(`//${ window.location.host }${process.env.REACT_APP_ZUORA_ENDPOINT_TOKEN}`,{},{
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                })
                const token = res_token.data.access_token;
                setCurrentToken( token );
                console.log( res_token.data  )
                const res_signature = await axios.post(`//${ window.location.host }${process.env.REACT_APP_ZUORA_ENDPOINT_SIGNATURE}`, { token }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                console.log(res_signature)
                setParams({
                    id: `${ process.env.REACT_APP_PAGE_ID }`,
                    tenantId: res_signature.data.tenantId,
                    token: res_signature.data.token,
                    key: res_signature.data.key,
                    signature: res_signature.data.signature,
                    submitEnabled:"true",
                    locale:"en_AU",
                    param_supportedTypes:"AmericanExpress,JCB,Visa,MasterCard,Discover,Dankort",
                    url: `${ process.env.REACT_APP_ZUORA_APP_DOMAIN }`,
                    style:"inline",
                    field_accountId: accountid,
                    brand: brand,
                });
                resolve(true)
            }catch (e) {
                console.log(e)
                reject(false)
            }

        })
    } ,[params])

    useEffect( ()=> {
        ;( async ()=> {
            const url = window.location.search.replace('?', '');
            const p = url.split('&');
            const brand =p[0].split('=')[1];
            const accountid = p[1].split('=')[1]
            console.log(brand , accountid)
            setBrand( brand );
            setAccountid( accountid );
            await setUpZuora(brand, accountid);

            window.addEventListener("message", (event) => {
                console.log(event);
            }, false);
        })();
    }, [])


    const prepopulateFields = {
        creditCardAddress1:"123 Any Street",
        creditCardAddress2:"Suite #999",
        creditCardCountry:"USA",
        creditCardHolderName:"John Doe"
    };

    // // Sample params for rendering iframe on the client side
    // const params2 = {
    //     tenantId:5000712,
    //     id:"8ac6832a81f1b8460181f6476f455095",
    //     token:"fKhyY7Th4LH0EUURCELCjs7P7qGQWBXG",
    //     signature:"avhYl5dVDF+Uczy7V1koA3Y9AX58aMddWsprJ884Hwkdnze8l/Kb8fULOtVu+gb68baplF1ed+QzcPYMO/ns1XwrWNYKR7WLh+ZazMS3Zw2w1xekg/eytf9XJcWRvjYaWzo8dQEhTtk5TFiOq00HxJ2vCdkGWXcSxL2zcwEB4MniKjw+u8L+Y4KtH5bv2F6dF0W31SyHtg11l1dKbkpDr+d63nUUjbGpCtf4T79Am7MuDpv8193xkb7QjEwGNLb2Tjyn90EW0izhwLtXf08fvlo88CX96ANxgxBOL4+iPQGs49B/j1jB8RlyT2RUXUaIXCm5PZ7DL8jS8X1jAIQqJA==",
    //     style:"inline",
    //     key: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnmYNgQpTw/7XW+1deETcPaOVfouCERug+t+9n5Jn9zXR4Q0gBs543+b8Vh/xSTWIyp8msHe2h68LFdQ/mV+EErXCANl6P0VYRKFrF+i9syqfoosdsQuYtbGaUpFpuz8pihgTW1Z8jkQz76RGHs/bngOZAkkMe8rObXsTBMrqV309zx6N7f5C2ctMgP9+LL6WUMCIVCn92qHxsH4cDRXyF029OHoPkH/46jbiqaGcfDsG4dzR9Ck2GHchzFs148Fi1dHSKNCE2NbR9Ok40kDgHecBNvkXIbxTVqC9vndveTsGx+sRXe2F6XohNaUpTqyXenRUK+UFDC7oLxHgi34YPQIDAQAB",
    //     submitEnabled:"true",
    //     locale:"en_AU",
    //     param_supportedTypes:"AmericanExpress,JCB,Visa,MasterCard,Discover,Dankort",
    //     url:"https://sandbox.na.zuora.com/apps/PublicHostedPageLite.do",
    //     field_accountId: accountid,
    //     brand: brand
    //     // paymentGateway: "Adyen"
    //     // paymentGateway:"DefaultGateway" //payment gateway name
    //     // paymentGateway:"0230-4082-5466" //payment gateway name
    // };

    async function callback(response: { success: any; refId: string; field_passthrough1: string; errorCode: string; errorMessage: string; }) {
        if(response.success) {
            const res = await axios.put(`//${ window.location.host }${process.env.REACT_APP_ZUORA_ENPOINT_USER}`,{ token: currentToken, accountId: accountid },{
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            });
            console.log( res.data.success );
            res.data.success && window.location.replace('/subscriptionCallback');
            !res.data.success && alert('ZUORA Subscription error');
        } else {
            alert("errorcode="+response.errorCode + ", errorMessage="+response.errorMessage);
        }
    }

    function loadHostedPage() {
        console.log( 'LOADING ZUORA' )
        Z.setEventHandler("onloadCallback", function() {
            console.warn("HPM page is loaded.");
        });

        Z.render(
            params,
            prepopulateFields,
            callback
        );
    }

    return (
        <main className={'main'}>
            { params && <ZuoraElement Z={Z} load={ ()=> loadHostedPage() }/> }
        </main>
    )
}


const ZuoraElement = ({Z, load } : { Z: any, load: Function }) => {
    useLayoutEffect( ()=> {
        load();
    } ,[])

    /*
        const onAgree = () => {
            if( (document.getElementById("agreement") as HTMLInputElement)!.checked) {
                if (!Z.setAgreement("External","Recurring","Visa","Your_Ref"))
                    return;
            } else {
                if(!Z.setAgreement("","","",""))
                    return;
            }
        }
    */
    return (
        <>
            <div id="zuora_payment"></div>
            {/*
            <div id="checkBoxDiv" className="item">
                <h1> Accept Terms </h1>
                <input className="form-check-input" id="agreement" type="checkbox" onClick={ () => onAgree()} />
                <label htmlFor="agreement"> I agree </label>
            </div>
            */}
        </>


    )
}



const useUpdateEffect = (callback: Function, deps: any[]) => {
    const firstRenderRef = useRef(true);

    useEffect( ()=> {
        if ( firstRenderRef.current ) {
            firstRenderRef.current = false;
            return;
        }
        else {
            return callback();
        }
    } ,[...deps])
}