import { useContext } from "react";
import { ContentfulEntry, ContentfulPicture } from '../../../common/interfaces';

import { HomeContext } from '../context';

import styles from './styles.module.scss';

const LegendaryCustomers = () => {
    const homeContext = useContext(HomeContext);

    const headline = homeContext["Headline > We've proudly delivered to 2 million LEGENDARY customers across Australia!"]?.headline;
    const headlineWords = headline?.split(' ');

    const bannerType = window.innerWidth < 768 ? 'Mobile' : 'Desktop';

    const bannerSections: ContentfulEntry[] = homeContext['Banner > 2 million legendary customers']?.sections;
    const banners = (bannerSections?.find(({ name }) => name.includes(bannerType))?.pictures as ContentfulPicture[]);

    return (
        <div className={styles.root}>
            <h1>
                {headlineWords?.slice(0, 4).join(' ')}
                <span> {headlineWords?.slice(4, 7).join(' ')} </span>
                {headlineWords?.slice(7).join(' ')}
            </h1>
            {banners && banners.length > 0 ?
                (<a href="https://www.facebook.com/winningservicesau/" target="_blank" rel="noreferrer" className={styles.imageContainer}>
                    <img src={banners[0]?.file} alt="Legendary customers" />
                </a>) : ''}
        </div>
    );
};

export default LegendaryCustomers;
