import {useContext} from "react";

import { ContentfulContext } from "../../context";
import { renderRichText } from "../../common/utils";

import styles from './styles.module.scss';
import clsx from "clsx";

const TermsAndConditions = () => {
    const context = useContext(ContentfulContext);

    const entries = context?.entries as any;

    return (
        <div className={styles.root}>
            <section>
                <div className={clsx("row", styles.title)}>
                    <div className="col-lg-8 offset-lg-2">
                        <h1>{entries['Paragraph > Terms & conditions']?.headline}</h1>
                    </div>
                </div>
            </section>

            <section className={styles.content}>
                <div className="row">
                    <div className="col-md-8 offset-md-2 g-4">
                        {renderRichText(entries['Paragraph > Terms & conditions']?.paragraph)}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TermsAndConditions;
