import {FC} from "react";

import styles from './styles.module.scss';

interface Props {
   title: string;
   text: string;
   imageSrc: string;
   route: string;
   arrowIcon: string;
}

const Card: FC<Props> = ({ title, text, imageSrc, route, arrowIcon }) => {

    return (
        <div className={styles.card} onClick={() => route && (window.location.href = route)}>
            {imageSrc ? <img src={imageSrc} alt={title}/> : ''}

            <span className={styles.cardCorner}/>

            <div className={styles.cardText}>
                <h3 className={styles.header}>{title}</h3>

                <div className={styles.cardDescription}>
                    <p>{text}</p>

                    <img src={arrowIcon} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default Card;
