import { FC } from "react";

import { WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const DeliveryProcess: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Delivery process'];

    const paragraphs = sectionData?.paragraph?.split('\n\n');

    return (
        <div className={styles.root}>
            <h2>{sectionData?.headline}</h2>

            <div className={styles.content}>
                {sectionData && sectionData['pictures'] && <img src={sectionData['pictures'][0]?.file} alt="Delivery process"/>}

                <p>
                    {paragraphs && paragraphs[0]}

                    <br/>
                    <br/>

                    {paragraphs && paragraphs[1]}
                </p>
            </div>

            <p className={styles.footText}>{context['Paragraph > Delivery is the ‘moment of truth’ for customers']?.paragraph}</p>
        </div>
    )
}

export default DeliveryProcess;
