import styles from './styles.module.scss';
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { ReadMoreButton, NetPromoterScore, ContactPanel } from "../../common/components";
import { addBackground } from '../../common/utils';
import { ContentfulPicture } from "../../common/interfaces";

import Products from "./Products";
import AssemblyMap from "./AssemblyMap";
import Partners from "./Partners";


const Installation = () => {
    const [installationContext, setInstallationContext] = useState<any>({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/pages/installation`)
            .then(response => response.json())
            .then(result => {
                setInstallationContext(result);
            })
            .catch((err) => console.error(err));
    }, []);

    const headData = installationContext['Paragraph > Get your appliance installed exactly how you want it'];

    const backgroundPictures: ContentfulPicture[] = installationContext['Paragraph > Get your appliance installed exactly how you want it']?.pictures;

    const desktopBackground = backgroundPictures?.find(({ title }) => title.includes('Desktop'))?.file || '';
    const mobileBackground = backgroundPictures?.find(({ title }) => title.includes('Mobile'))?.file || '';

    const paragraphs = headData?.paragraph?.split('\n\n');

    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    function toggleDescription() {
        setDescriptionExpanded(!descriptionExpanded);
    }

    return (
        <div>
            <section className={clsx(styles.head, 'row')} style={addBackground(desktopBackground, mobileBackground)}>
                <div className="col-lg-8 offset-lg-2 d-flex justify-content-center">
                    <h1>
                        {headData?.headline}
                    </h1>
                </div>
                <div className="col-lg-8 offset-lg-2 d-flex justify-content-center">
                    <h2>{headData?.subheadline}</h2>
                </div>
            </section>

            <section className={clsx(styles.installationText, "row", descriptionExpanded && styles.expanded)}>
                <div className={clsx('offset-md-1 col-md-5 d-flex flex-column align-items-center', styles.installationParagraph1)}>
                    <p>{paragraphs && paragraphs[0]}</p>

                    <p>{paragraphs && paragraphs[1]}</p>
                </div>

                <div className={clsx('col-md-5 d-flex flex-column align-items-center', styles.installationParagraph2)}>
                    <p>{paragraphs && paragraphs[2]}</p>

                    <p>{paragraphs && paragraphs[3]}</p>

                    <p>{paragraphs && paragraphs[4]}</p>
                </div>

                {!descriptionExpanded && <ReadMoreButton onClick={toggleDescription}/>}
            </section>

            <Products context={installationContext}/>

            <AssemblyMap context={installationContext}/>

            <Partners context={installationContext}/>

            <NetPromoterScore context={installationContext}/>

            {Object.keys(installationContext).includes('Contact form > Want a winning partner?') && <ContactPanel/>}
        </div>
    )
}

export default Installation;
