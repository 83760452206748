import { FC } from "react";

import {ContentfulLogo, ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const OurImpact: FC<WithContext> = ({ context }) => {
    const sectionData = context['Background Image > Our Impact'];
    const background = sectionData?.pictures[0].file ?? '';
    
    const sectionData1 = context['Paragraph > Our Impact (part 1)'];
    const paragraphs = (sectionData1?.paragraph ?? '').split("\n\n");

    const sectionData2 = context['Paragraph > Our Impact (part 2)'];
    const logos: ContentfulLogo[] = sectionData2 ? sectionData2.pictures : [];
    const logoCollection = logos
    .filter(logo => logo.pictures)
    .map( logo => {
        return{
            ...logo,
            pictures:(logo.pictures as ContentfulPicture[])[0] ?? ''
        };
    });
    
    return (
        <div className={styles.root} style={{ backgroundImage: `url(${background})`, 
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
        }}>
            <div className={styles.content}>
                {sectionData1 && sectionData1['pictures'] && <img src={sectionData1['pictures'][0]?.file} alt="Our Impact Banner"/>}
                <div>
                    <h3>{sectionData1?.headline}</h3>
                    <p>
                        {paragraphs.map((paragraph:any) => (  
                            <>
                            <span dangerouslySetInnerHTML={{__html: paragraph}} />
                            <br/>
                            <br/>
                            </>
                        ))}  
                    </p>
                </div>
            </div>

            <div className={styles.row}>
			{logoCollection.map(({ pictures, headline, subheadline }) => (
                <div className={styles.column}>
                        <div className={styles.logo_container}>
                            <img src={pictures?.file} className={styles.logo} alt="" />
                        </div>
                        <p className={styles.title}>{headline}</p>   
                        <p className={styles.subtitle}>{subheadline}</p>                       
                </div>
			))}
                <div className={styles.cta}>
                    <a href={sectionData2?.subheadlineOptional} className={styles.button}>{sectionData2?.headline}</a>
                </div>
			</div>

        </div>

    )
}

export default OurImpact;
