import { FC } from "react";

import { TeamMember } from "../index";
import styles from './styles.module.scss';
import clsx from "clsx";

interface Props {
    member: TeamMember;
}

const MemberCard: FC<Props> = ({ member }) => {
    const { paragraph, headline, pictures } = member;

    return (
        <div className="col-md-4 d-md-flex flex-column align-items-center">
            <img src={pictures && pictures[0].file} className={clsx(styles.memberImage, "lazyload")} alt={headline}/>

                <p className={styles.subtitle}>{headline}</p>

                <p className={styles.description}>{paragraph}</p>
        </div>
    )
}

export default MemberCard;
