import React, { FC } from 'react';

import { TrianglePicture } from "../../../common/components";
import {ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const OurHeroes: FC<WithContext> = ({ context }) => {
	const sectionData = context['Paragraph > Our heroes behind the brand'];
	const paragraphs = sectionData?.paragraph?.split('\n\n');

	const pictures: { name: string; pictures: ContentfulPicture[] }[] = sectionData ? sectionData.pictures : [];
	const flatPictures: ContentfulPicture[] = pictures.map(({ pictures }) =>  pictures[0]);
	const [picture1, picture2, picture3] = flatPictures;

	return (
		<div className={styles.ourHeroes}>
			<TrianglePicture color="grey"/>

			<div className={`row ${styles.heroesContent}`}>
				<div className="col-md-5 offset-md-1">
					<div className={`visible-xs visible-sm ${styles.mobileImageContainer}`}>
						<img src={picture1?.file} className={`${styles.hero1} lazyload`} alt="" />
					</div>
					<div className="hidden-sm hidden-xs">
						<img src={picture1?.file} className={`${styles.hero1} lazyload`} alt="" />
						<br />
						<img src={picture2?.file} className={`${styles.hero2} lazyload d-none d-md-inline`}	alt="" />
						<img src={picture3?.file} className={`${styles.hero3} lazyload d-none d-md-inline`}	alt="" />
					</div>
				</div>

				<div className={`col-md-5 ${styles.heroesText}`}>
					<h3>{sectionData?.headline}</h3>

					{paragraphs?.map((paragraph: string, i: number) => <p key={`our-heroes${i}`}>{paragraph}</p>)}
				</div>
			</div>
		</div>
	)
}

export default OurHeroes;
