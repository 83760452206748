import { FC } from "react";

import {ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const AwardBanner: FC<WithContext> = ({ context }) => {
    const sectionData = context['Banner > Retail Sustainability Award'];
    if (!sectionData) return null;
    
    const bannerType = window.innerWidth < 768 ? 'Mobile' : 'Desktop';

    const bannerPicture: ContentfulPicture[] = sectionData.pictures;
    const banner = bannerPicture?.find(({ title }) => title.includes(bannerType));

    return (
        <div className={styles.root}>
            {sectionData?.headline ?
            <h1>
                {sectionData?.headline}
            </h1>
            : ''}

            {banner &&
                (<a href={sectionData?.link} className={styles.imageContainer}>
                    <img src={banner.file} alt={sectionData?.subheadline} />
                </a>) }

        </div>
    );
};

export default AwardBanner;
