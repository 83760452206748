import { FC } from "react";

import { WithContext } from "../../../common/interfaces";

import styles from './styles.module.scss';

const ApproachAndStrategy: FC<WithContext> = ({ context }) => {
    const sectionData = context['Paragraph > Approach & Strategy'];
    const paragraphsAddHTMLlinks = sectionData?.paragraph.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a target="_blank" href="$2">$1</a>') ?? ''
    const paragraphs = (paragraphsAddHTMLlinks ?? '').split("\n\n");
    
    return (
        <div className={styles.root}>
            <h3 id={encodeURI(sectionData?.headline)}>{sectionData?.headline}</h3>

            <div className={styles.content}>
                <h4>{sectionData?.subheadline}</h4>
                {sectionData && sectionData['pictures'] && <img src={sectionData['pictures'][0]?.file} alt="Approach & Strategy Banner"/>}
                <p>
                    {paragraphs.map((paragraph:any) => (  
                        <>
						<span dangerouslySetInnerHTML={{__html: paragraph}} />
                        <br/>
                        <br/>
                        </>
					))}  
                </p>
            </div>
        </div>
    )
}

export default ApproachAndStrategy;
