import clsx from "clsx";
import { FC } from 'react';

import {ContentfulPicture, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';
import MemberCard from "./MemberCard";

export interface TeamMember {
    name: string;
    headline: string;
    paragraph: string;
    pictures: ContentfulPicture[];
}

const Team: FC<WithContext> = ({ context }) => {
    const membersKeys: string[] = context ? Object.keys(context).filter((key) => key.includes('Team >')): [];
    const members: TeamMember[] = membersKeys.map((key: string) => context[key]);

    return (
        <section className={styles.root}>
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <h3>
                        {context['Headline > Meet the Winning Services team']?.headline}
                    </h3>
                </div>
            </div>

            <div className="row">
                <div className={clsx("col-md-10 offset-md-1", styles.team)}>
                    {members.map((member) => <MemberCard key={member.headline} member={member} />)}
                </div>
            </div>
        </section>
    )
}

export default Team;
