import React, { FC } from 'react';
import clsx from "clsx";

import { WithContext } from "../../../common/interfaces";

import ServiceItem from './Serviceitem';
import styles from './styles.module.scss';

const WarehouseService: FC<WithContext> = ({ context }) => {
  return (
    <div className={styles.warehouseService}>
      <div className="row">
        <div className="col-md-12">
          <h3>
            {context['Headline > Our warehousing services']?.headline}
          </h3>

        </div>
      </div>
      <div className="row">
        <div className={clsx("col-lg-10 col-xxl-8 offset-lg-1 offset-xxl-2", styles.services)}>
          <div className={`d-flex flex-column flex-md-row justify-content-between ${styles.servicesRow}`}>
            {context['Warehousing > Services']?.sections.map((s: any) => (
              <div className={styles.serviceDescription}>
                <ServiceItem
                  text={s?.paragraph as string}
                  title={s?.headline as string}
                />
              </div>))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarehouseService;
