import React, { FC, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse'

import styles from './styles.module.scss';
import clsx from "clsx";

interface Props {
  title: string;
  text: string;
}

const ServiceItem: FC<Props> = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <h4
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        className={clsx(styles.title, !open && styles.collapsed)}
      >
        {title}
      </h4>
      <Collapse in={window.innerWidth > 768 || open}>
        <p>
          {text}
        </p>
      </Collapse>
    </>
  );
}


export default ServiceItem;
