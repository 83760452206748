import { FC } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { addBackground } from "../../../common/utils";
import {ContentfulEntry, ContentfulPicture, ContentfulImage, WithContext} from "../../../common/interfaces";

import styles from './styles.module.scss';

const HistoryCarousel: FC<WithContext> = ({ context }) => {
  const pictures: ContentfulPicture[] = context['Paragraph > A long history of']?.pictures;
  const backgroundImage = pictures?.find(({ title }) => title.includes('Desktop'))?.file;
  const backgroundImageMobile = pictures?.find(({ title }) => title.includes('Mobile'))?.file;
  const title = context['Paragraph > A long history of'];
  const headlineImage = pictures?.find(({ title }) => title.includes('Home > Legendary care TM'))?.file; 
  const link: ContentfulEntry = context['Link > Learn more about us'];

  const sectionData = context['Set of images > Legendary care timeline'];
  if (!sectionData) return null;

  const keyDates: ContentfulImage[] = sectionData.sections;

  const CustomRight = ({ onClick }: any) => (
    <button className={`${styles.carouselButton} ${styles.buttonRight}`} onClick={onClick}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM13.1937 10.248L17.4931 15L13.1937 19.752C12.9354 20.0375 12.9354 20.5004 13.1937 20.7859C13.452 21.0714 13.8708 21.0714 14.1291 20.7859L18.8063 15.6164C18.9584 15.4483 19.0209 15.2187 18.9939 15C19.0209 14.7813 18.9584 14.5517 18.8063 14.3836L14.1291 9.21413C13.8708 8.92863 13.452 8.92863 13.1937 9.21413C12.9354 9.49963 12.9354 9.96252 13.1937 10.248Z" fill="#005DAA"/>
      </svg>
    </button>
  );
  const CustomLeft = ({ onClick }: any) => (
    <button className={`${styles.carouselButton} ${styles.buttonLeft}`} onClick={onClick}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C6.7157 30 0 23.2843 0 15C0 6.71573 6.7157 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30ZM16.8063 10.248L12.5069 15L16.8063 19.752C17.0646 20.0375 17.0646 20.5004 16.8063 20.7859C16.548 21.0714 16.1292 21.0714 15.8709 20.7859L11.1937 15.6164C11.0416 15.4483 10.9791 15.2187 11.0061 15C10.9791 14.7813 11.0416 14.5517 11.1937 14.3836L15.8709 9.21413C16.1292 8.92863 16.548 8.92863 16.8063 9.21413C17.0646 9.49963 17.0646 9.96252 16.8063 10.248Z" fill="#005DAA"/>
      </svg>
    </button>
  );

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4,
        partialVisibilityGutter: 10
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3,
        partialVisibilityGutter: 10
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 10
      }
    };

    return (
      <div className={styles.main} style={addBackground(backgroundImage, backgroundImageMobile)}>
        <div className={styles.root}>
            <h2 className={styles.title}>
                      {title?.headline}
                      <img src={headlineImage} alt="Legendary Care"/>
                  </h2>

                  <p className={styles.subtitle}>{title?.paragraph}</p>

            <Carousel 
              responsive={responsive} 
              partialVisible={true}
              customTransition="transform 1500ms ease-in-out"
              customRightArrow={<CustomRight />}
              customLeftArrow={<CustomLeft />}
              >
              {keyDates.map(({ headline, paragraph, pictures }) => {
                const paragraphs = (paragraph ?? '').split("\n\n");
                return (
                  <div className={styles.carousel}>
                    <div className={styles.imageContainer}>
                      <img className={styles.image} src={pictures && pictures[0]?.file} alt={"Winning Key Date " + headline}/>
                      <div className={styles.date}>{headline}</div>
                      <div className={styles.line}></div>
                    </div>
                    <p>
                    {paragraphs.map((paragraphItem:any) => (  
                      <>
                      {paragraphItem}
                      <br/>
                      <br/>
                      </>
                    ))}    
                    </p>
                  </div>
                )
              })}
            </Carousel>

            {link ? (<a className={styles.learnMore} href={link?.url}>{link?.headline}</a>) : ''}
        </div>
      </div>
    );
};

export default HistoryCarousel;