import styles from './styles.module.scss';
import Links from "./Links";
import LinksMobile from "./LinksMobile";
import { useState, useEffect } from 'react';

const Footer = () => {

    const [footerContext, setFooterContext] = useState({} as any);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/footer`)
            .then(response => response.json())
            .then(result => {
                setFooterContext(result);
            })
            .catch((err) => console.error(err));

    }, []);
    return (
        <footer className={styles.root}>
            <Links context={footerContext}/>
            <LinksMobile context={footerContext}/>
        </footer>
    )
}

export default Footer;
