import { useState, FC, useEffect } from "react";

import styles from './styles.module.scss';
import DescriptionModal from "./DescriptionModal";
import { WithContext } from "../../interfaces";

const NetPromoterScore: FC<WithContext> = ({ context }) => {
    const [modalOpened, setModalOpened] = useState(false);

    const npsHeadline = context ? context['Headline > The industry\'s best Net Promoter Score (NPS)'] : {};

    const npsSectionInfo = context ? context['Paragraph > Rate us 9 or 10 out of 10!'] : {};
    const npsImage = npsSectionInfo?.pictures ? npsSectionInfo.pictures[0] : {};

    const learMoreInfo = context ? context['Link > Learn more about our NPS'] : {};

    function closeModal() {
        if (!modalOpened) {
            return;
        }
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        // @ts-ignore
        window.scrollTo({ top: parseInt(scrollY || '0') * -1, left: 0, behavior: 'instant' });
        setModalOpened(false);
    }

    useEffect(
        () => {
            if (modalOpened) {
                const scrollY = window.scrollY;
                document.body.style.position = 'fixed';
                document.body.style.top = `-${scrollY}px`;
            }
        }, [modalOpened]);

    return (
        <div className={styles.root} onClick={closeModal}>
            <h2>{npsHeadline?.headline}</h2>

            <div className={styles.content}>
                <img src={npsImage.file} alt={npsImage.title} />

                <div className={styles.scoreInfo}>
                    <h3>{npsSectionInfo?.headline}</h3>

                    <span className={styles.disclaimer}>
                        {npsSectionInfo?.paragraph}
                    </span>
                    <br />

                    <a onClick={() => setModalOpened(true)} className={styles.descriptionButton}>{learMoreInfo?.headline}</a>
                </div>

                <DescriptionModal opened={modalOpened} onClose={closeModal} />
            </div>
        </div>
    )
}

export default NetPromoterScore;
