import { FC } from "react";

import { ContentfulPicture, LogoBound, WithContext } from "../../../common/interfaces";
import { renderBrands } from '../../../common/utils';

import styles from './styles.module.scss';

const isLaptop = window.innerWidth < 1480;
const isLaptopWithScaling = window.innerWidth < 1281;

const BOUNDS: LogoBound[] = [
    { height: isLaptopWithScaling ? 40 : isLaptop ? 47 : 50 },
    { height: isLaptopWithScaling ? 50 : isLaptop ? 62 : 70 },
    { height: isLaptop ? 51 : 60 },
    { height: isLaptopWithScaling ? 30 : isLaptop ? 41 : 40 },
    { height: isLaptopWithScaling ? 55 : isLaptop ? 68 : 70 },
    { height: isLaptopWithScaling ? 55 : isLaptop ? 75 : 70 },
    { height: isLaptopWithScaling ? 30 : isLaptop ? 45 : 40 },
    { height: isLaptopWithScaling ? 50 : isLaptop ? 67 : 60 },
    { height: isLaptopWithScaling ? 50 : isLaptop ? 65 : 70 },
]

const FIRST_LINE_OFFSET = 4;

const Brands: FC<WithContext> = ({ context }) => {
    const brandsData = context['Paragraph > Who we deliver for'];
    const logos: { pictures: ContentfulPicture[] }[] = brandsData?.pictures[0].sections.filter((section: Record<string, unknown>) => !!section.pictures);

    return (
        <div className={styles.root}>
            <h2>{brandsData?.headline}</h2>
            <p>{brandsData?.subheadlineOptional}</p>

            {logos && renderBrands(logos.map(({pictures}) => pictures[0]), FIRST_LINE_OFFSET, BOUNDS, styles)}
        </div>
    )
}

export default Brands
