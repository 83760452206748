import React,  { useState, useEffect, useRef, MutableRefObject } from 'react';
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import {
    ABOUT_ROUTE,
    CONTACT_ROUTE,
    PRIVACY_POLICY_ROUTE,
    TERMS_AND_CONDITIONS_ROUTE,
    SUSTAINABILITY_ROUTE,
} from '../../constant/routes';
import '../../common/styles.scss';
import { LazyImage } from "../../common/components";

import styles from './styles.module.scss';

const PAGES_WITH_ALTERNATIVE_COLOR = [ABOUT_ROUTE, CONTACT_ROUTE, TERMS_AND_CONDITIONS_ROUTE, PRIVACY_POLICY_ROUTE, SUSTAINABILITY_ROUTE];

const Header = () => {
    const ref: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const [navContext, setNavContext] = useState({} as any);
    const [menuOpened, setMenuOpened] = useState(false);

    const [openedElement, setOpenedElement] = useState('');

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setMenuOpened(false);
            }
        }

        document.addEventListener<'mousedown'>("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/contentful/navigation`)
            .then(response => response.json())
            .then(result => {
                setNavContext(result);
            })
            .catch((err) => console.error(err));

    }, []);

    const alternativeRightArea = PAGES_WITH_ALTERNATIVE_COLOR.includes(location.pathname);

    function toggleMenu() {
        setMenuOpened(!menuOpened);
    }

    function toggleDropdown(e: React.MouseEvent, element: string) {
        e.stopPropagation();

        if (element === openedElement) setOpenedElement('');
        else {
            setOpenedElement(element);
        }
    }
    //AU and NZ phones
    let  AUphone: string[] = [];
    if (navContext?.phone?.headline !== undefined) {
        AUphone = navContext?.phone?.headline.split(",");
    }
    let  NZphone: string[] = [];
    if (navContext?.phone?.link !== undefined) {
        NZphone = navContext?.phone?.link.split(","); 
    }

    return (
        <nav className={styles.root} ref={ref}>
            <div className={styles.navigation}>
                <div className={styles.flexContainer}>
                    <a href="/">
                        <LazyImage className={styles.logo} src={navContext?.logo?.file} alt="Winning Services"/>
                    </a>

                    <button onClick={toggleMenu} type="button" className={clsx(styles.burger, menuOpened && styles.burgerOpened)}>
                        <span />
                        <span />
                        <span />
                    </button>
                </div>

                <ul className={clsx(styles.menu, menuOpened && styles.opened)}>
                    {navContext?.navigationElements?.map((nav: any) => nav.sublinksOptional ? (
                        <li key={nav.name} className={styles.withDropDown} onClick={(e) => toggleDropdown(e, nav.name)}>
                            <span className={clsx(openedElement === nav.name && styles.spanWithArrow)}>
                                {nav.headline}
                            </span>

                            <ul className={clsx(styles.dropdown, openedElement === nav.name  && styles.dropdownOpened)}>
                                {nav.sublinksOptional.map((sublink: any) => (
                                    <li key={sublink.name}>
                                        <a href={sublink?.url}>
                                            {sublink?.headline}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ) : (
                        <li key={nav.name}>
                            <span>
                                <a href={nav.url}>
                                    {nav.headline}
                                </a>
                            </span>
                        </li>)
                    )}
                    <li className={styles.phoneNumberForMobile}>
                        <div>
                            <LazyImage src={navContext?.phone?.pictures[1]?.file} alt="Phone icon" />
                            <div className={styles.phoneNumberContainer}>
                                {AUphone?.at(0) ? <a href={AUphone?.at(0)}>{AUphone?.at(1)}</a> : null}
                                {NZphone?.at(0) ? <a href={NZphone?.at(0)}>{NZphone?.at(1)}</a> : null}
                            </div>
                            
                        </div>

                        <span>
                            <a href={navContext?.login?.url} target="_blank" rel="noreferrer">
                                {navContext?.login?.headline}
                            </a>
                        </span>
                    </li>
                </ul>
            </div>

            <div className={styles.rightArea}>
                <div className={styles.phoneNumber}>
                    <LazyImage src={alternativeRightArea ? navContext?.phone?.pictures[0]?.file : navContext?.phone?.pictures[1]?.file} alt="Phone icon" />
                    <div className={styles.phoneNumberContainer}>
                        {AUphone?.at(0) ? <a href={AUphone?.at(0)} className={clsx(alternativeRightArea && styles.blue)}>{AUphone?.at(1)}</a> : null}
                        {NZphone?.at(0) ? <a href={NZphone?.at(0)} className={clsx(alternativeRightArea && styles.blue)}>{NZphone?.at(1)}</a> : null}
                    </div>
                </div>
                <a
                    href={navContext?.login?.url}
                    target="_blank"
                    rel="noreferrer"
                    className={clsx(alternativeRightArea && styles.blue)}
                >
                    {navContext?.login?.headline}
                </a>
            </div>

        </nav>
    )
}

export default Header;
